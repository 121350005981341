import React, {
  ReactElement,
  MouseEvent,
  useEffect,
  useState,
  useRef,
} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TogglePlay from '../icons/TogglePlay'
import SoundControl from '../icons/SoundControl'
import ToggleFullScreen from '../icons/ToggleFullScreen'
import Play from '@components/core/customCursor/icons/Play'
import clsx from 'clsx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { YouTubePlayer as YoutubePlayerTypes } from 'youtube-player/dist/types'
import YouTubePlayer from 'react-youtube'

import useCustomCursor from '@system/hooks/useCustomCursor'

const useStyles = makeStyles((theme) => ({
  SSFYouTubePlayer: {
    position: 'relative',
    overflow: 'hidden',
    '& .uc-embedding-container': {
      position: 'absolute',
      zIndex: 100,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'auto',
      '& h3': {
        ...theme.typography.h3,
      },
      '& .uc-embedding-wrapper': {
        padding: theme.spacing(4),
        borderRadius: 0,
        width: '480px',
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
        ...theme.typography.body1,
        lineHeight: 1.5,
        '& .uc-embedding-more-info': {
          borderRadius: 0,
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          ...theme.typography.button,
        },
        '& .uc-embedding-accept': {
          borderRadius: 0,
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          ...theme.typography.button,
        },
      },
      '& img': {
        display: 'none',
      },
    },
    '&.isSmartPhone.is-playing iframe': {
      pointerEvents: 'auto',
    },
  },
  playerWrapper: {
    paddingBottom: 'calc(100vw * 1.25)',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '56.25%',
    },
    paddingTop: '0px',
    height: 0,
    overflow: 'hidden',
    position: 'relative',
    '&.isFullscreen': {
      paddingBottom: 'initial',
      height: '100%',
    },
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  poster: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 1,
    transition: 'opacity .5s ease-out 0.5s',
    opacity: 1,
    pointerEvents: 'none',
    '&.fadeOut': {
      opacity: 0,
    },
    '&.hidden': {
      opacity: 0,
      display: 'none',
      zIndex: -1,
    },
  },
  posterImage: {
    width: '100%',
    '&.gatsby-image-wrapper': {
      overflow: 'inherit',
      [theme.breakpoints.up('sm')]: {
        overflow: 'hidden',
      },
    },
  },
  playButton: {
    position: 'absolute',
    top: 'calc(50% - 24px)',
    left: 'calc(50% - 24px)',
    width: '48px',
    height: '48px',
    background: '#29ace3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '24px',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  controls: {
    background: theme.palette.background.focus,
    width: '100%',
    height: '48px',
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
    bottom: '0px',
    opacity: 1,
    transition: 'bottom .5s ease-out, opacity .5s ease-out',
    '&.hide': {
      bottom: '-49px',
      opacity: 0,
    },
    '&.isTouchAble .progressBar': {
      width: 'calc(100% - 100px)',
    },
    '&.isSmartPhone': {
      display: 'none',
    },
  },
  btnPlay: {
    width: '60px',
    height: '100%',
    background: '#99000066',
    textAlign: 'center',
    lineHeight: '52px',
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
  btnPause: {
    width: '60px',
    height: '100%',
    background: '#99000066',
    textAlign: 'center',
    lineHeight: '52px',
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
  progressBar: {
    width: 'calc(100% - 180px)',
    height: '100%',
    position: 'relative',
    top: '0px',
  },
  progressBarInner: {
    background: '#acb3c0',
    width: '100%',
    height: '3px',
    position: 'relative',
    top: '23px',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#29ace3',
    width: '0%',
    height: '100%',
    zIndex: 2,
  },
  status: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '0%',
    height: '100%',
    background: '#f4f6fc',
    zIndex: 1,
  },
  btnVolume: {
    width: '60px',
    height: '100%',
    background: '#99000066',
    textAlign: 'center',
    lineHeight: '52px',
    '& > span': {
      width: '16px',
      height: '16px',
      background: '#ff9900',
      display: 'inline-block',
    },
  },
}))

export type VideoProps = DBN.IReactDefaultProps & {
  titleInternal?: string
  anchor?: string
  youtubeId?: string
  poster?: Array<DBN.Contentful.IAsset>
  posterMobile?: Array<DBN.Contentful.IAsset>
}

export default function Video({
  anchor,
  youtubeId,
  poster,
  posterMobile,
}: VideoProps): ReactElement {
  const classes = useStyles()

  const [isPlaying, setPlayStatus] = useState(false)
  const [player, setPlayer] = useState<YoutubePlayerTypes | null>(null)
  const [volume, setVolume] = useState(0.5)
  const [isFullscreen, setFullscreen] = useState(false)
  const [isTouchAble, setIsTouchAble] = useState(true)
  const [progress, setProgress] = useState(0)
  const [loaded, setLoaded] = useState(0)
  const [controlsHidden, setHideControls] = useState(false)
  const playerRef = useRef<HTMLDivElement>(null)
  const [playerPosition, setPlayerPosition] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const [isSmartPhone, setIsSmartPhone] = useState(false)

  const progresBarRef = useRef<HTMLDivElement>(null)
  const [posterState, setPosterState] = useState('')

  // https://developers.google.com/youtube/player_parameters
  // const opts =

  const _onPlay = () => {
    setPlayStatus(true)
  }

  const { setCursorType, disableCursor, enableCursor } = useCustomCursor()

  useEffect(() => {
    let isMobile = window.matchMedia(
      'only screen and (max-width: 767px)'
    ).matches

    if (
      /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      isMobile = true
    }

    setIsSmartPhone(isMobile)

    const handleResize = () => {
      setIsMobileDevice(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!player) return
      const duration = player.getDuration()
      const currentTime = player.getCurrentTime()
      const videoBytesTotal = player.getVideoBytesTotal()
      const bytesLoaded = player?.getVideoBytesLoaded()
      setProgress(Math.ceil((currentTime / duration) * 100))
      setLoaded(Math.ceil((bytesLoaded / videoBytesTotal) * 100))
    }, 100)

    setIsTouchAble(() => {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
    })
    return () => {
      clearInterval(interval)
    }
  }, [player, progress])

  // Add event listeners
  useEffect(() => {
    const onKeyDownHandler = (key: KeyboardEvent) => {
      const duration = player?.getDuration() || 1
      const currentTime = player?.getCurrentTime() || 0
      let time = 0
      let pin = 0
      switch (key.code) {
        // space
        case 'Space':
          togglePlayHandler()
          pin++
          break
        // left
        case 'ArrowLeft':
          time = Math.round(Math.min(duration, currentTime - 3))
          player?.seekTo(Math.round(Math.max(0, time)), true)
          pin++
          break
        // right
        case 'ArrowRight':
          time = Math.round(Math.min(duration, currentTime + 3))
          player?.seekTo(time, true)
          break
        // up
        case 'ArrowUp':
          onVolumeHandler(null, Math.min(volume + 0.1, 1))
          pin++
          break
        // down
        case 'ArrowDown':
          onVolumeHandler(null, Math.max(volume - 0.1, 0))
          pin++
          break
        // f
        case 'KeyF':
          toggleFullscreenHandler()
          pin++
          break
      }
      if (key && pin > 0) key.preventDefault()
    }
    window.addEventListener('keydown', onKeyDownHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', onKeyDownHandler)
    }
  }, [volume, isPlaying, progress, player])

  const initPlayer = (target: YoutubePlayerTypes) => {
    setPlayer(target)
    setPlayerPosition(getPosition(playerRef.current).top - 50)
    playerRef.current.set
  }

  const _onPause = () => {
    setPlayStatus(false)
  }

  const onClickHandler = (e: MouseEvent): void => {
    const lPos: number = progresBarRef?.current?.offsetLeft || 0
    const lWidth: number = progresBarRef?.current?.offsetWidth || 0
    const percent = (e.clientX - lPos) / lWidth || 0
    const duration = player?.getDuration() || 0
    player?.seekTo(Math.round(duration * percent), true)
  }

  function StatusBar(props: {
    progress: number
    loaded: number
    fullscreen: boolean
  }) {
    const bar1 = props.progress
    const bar2 = props.loaded
    return (
      <div
        ref={progresBarRef}
        className={clsx(classes.progressBar, 'progressBar')}
        onClick={(e) => onClickHandler(e)}
      >
        <div className={classes.progressBarInner}>
          <div className={classes.progress} style={{ width: `${bar1}%` }} />
          <div className={classes.status} style={{ width: `${bar2}%` }} />
        </div>
      </div>
    )
  }

  const togglePlayHandler = (e: MouseEvent | null) => {
    if (e) e.preventDefault()
    setPlayStatus(!isPlaying)
    if (isPlaying === true) {
      player?.pauseVideo()
      setCursorType('play')
      hideControls(false, 0)
    } else {
      setCursorType('play-pause')
      hideControls(true, 2)
      player?.playVideo()
      hidePoster()
    }
  }

  const hidePoster = () => {
    if (posterState !== 'hidden') {
      setPosterState('fadeOut')
      setTimeout(() => {
        setPosterState('hidden')
      }, 1000)
    }
  }

  const onVolumeHandler = (event: React.ChangeEvent, newValue: number) => {
    setVolume(newValue)
    player?.setVolume(Math.ceil(newValue * 100))
    if (newValue > 0) {
      player?.unMute()
    } else {
      player?.mute()
    }
  }

  const _onFullscreenChange = (status) => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setFullscreen(false)
      enableCursor()

      if (document.removeEventListener) {
        document.removeEventListener(
          'webkitfullscreenchange',
          _onFullscreenChange
        )
        document.removeEventListener('mozfullscreenchange', _onFullscreenChange)
        document.removeEventListener('fullscreenchange', _onFullscreenChange)
        document.removeEventListener('MSFullscreenChange', _onFullscreenChange)
      }
      // document.body.classList.remove('fullscreen')

      // setTimeout(() => {
      scrollToVideo()
      // }, 500);
    } else {
      setFullscreen(true)
      disableCursor()
      // document.body.classList.add('fullscreen')
    }
  }

  const toggleFullscreenHandler = (e: MouseEvent) => {
    if (e) e.preventDefault()

    const plr = playerRef?.current

    const requestFullScreen =
      plr.requestFullscreen ||
      plr.webkitEnterFullscreen ||
      plr.mozRequestFullScreen ||
      plr.msRequestFullscreen

    if (isFullscreen !== true && requestFullScreen) {
      try {
        if (document.addEventListener) {
          document.addEventListener(
            'webkitfullscreenchange',
            _onFullscreenChange,
            false
          )
          document.addEventListener(
            'mozfullscreenchange',
            _onFullscreenChange,
            false
          )
          document.addEventListener(
            'fullscreenchange',
            _onFullscreenChange,
            false
          )
          document.addEventListener(
            'MSFullscreenChange',
            _onFullscreenChange,
            false
          )
        }
        requestFullScreen.bind(plr)()
      } catch (e: Error) {
        console.log(e)
      }
    } else {
      document.exitFullscreen()
    }
    hideControls(false, 1)
  }

  let intID: NodeJS.Timeout

  const hideControls = (bool: boolean, delay = 0) => {
    clearTimeout(intID)
    if (bool !== controlsHidden) {
      const time = bool === true ? delay * 1000 : 0
      intID = setTimeout(() => {
        setHideControls(bool)
      }, time)
    }
  }
  const getPosition = (el) => {
    let x = 0
    let y = 0
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft
      y += el.offsetTop - el.scrollTop
      el = el.offsetParent
    }
    return { top: y, left: x }
  }

  const scrollToVideo = () => {
    if (window) {
      window.scrollTo(0, playerPosition)
    }
  }

  const getImg = isMobileDevice ? getImage(posterMobile) : getImage(poster)

  return (
    <>
      {youtubeId && (
        <div
          className={clsx(
            classes.SSFYouTubePlayer,
            isSmartPhone === true ? 'isSmartPhone' : '',
            posterState !== '' ? 'is-playing' : ''
          )}
          ref={playerRef}
        >
          <div
            className={clsx(
              classes.playerWrapper,
              isFullscreen === true ? 'isFullscreen' : ''
            )}
            onMouseEnter={() => {
              hideControls(false, 0)
              setCursorType(`play${isPlaying === true ? '-pause' : ''}`)
            }}
            onMouseLeave={() => {
              hideControls(true, 2)
              setCursorType('')
            }}
            onClick={(e) => togglePlayHandler(e)}
          >
            <YouTubePlayer
              className={classes.player}
              videoId={youtubeId}
              opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                  autoplay: 0,
                  controls: isSmartPhone === true ? 2 : 0,
                  color: 'white',
                  enablejsapi: 1,
                  playsinline: 1,
                  fs: 1,
                  iv_load_policy: 3,
                  modestbranding: 1,
                  rel: 0,
                  disablekb: 0,
                },
              }}
              fullscreen={isFullscreen}
              onReady={({ target }) => {
                // ytp-title-expanded-overlay
                initPlayer(target)
              }}
              onPlay={_onPlay}
              onPause={_onPause}
              allowFullscreen
            />
          </div>
          <div
            className={clsx(
              classes.controls,
              controlsHidden === true ? 'hide' : '',
              isTouchAble === true ? 'isTouchAble' : '',
              isSmartPhone === true ? 'isSmartPhone' : ''
            )}
            onMouseEnter={() => {
              hideControls(false, 0)
              setCursorType(`small`)
            }}
            onMouseLeave={() => {
              hideControls(true, 2)
              setCursorType('')
            }}
          >
            <TogglePlay
              isPlaying={isPlaying}
              onClick={togglePlayHandler}
              fullscreen={isFullscreen}
            />
            <StatusBar
              progress={progress}
              loaded={loaded}
              fullscreen={isFullscreen}
            />
            {!isTouchAble && (
              <SoundControl
                volume={volume}
                muted={player?.isMuted()}
                onChange={onVolumeHandler}
                onToggleMute={() => {
                  if (player?.isMuted() === true) {
                    player?.unMute()
                  } else {
                    player?.mute()
                  }
                }}
                fullscreen={isFullscreen}
              />
            )}
            <ToggleFullScreen
              isFullscreen={isFullscreen}
              onClick={toggleFullscreenHandler}
            />
          </div>

          <div className={clsx(classes.poster, posterState)}>
            {getImg && (
              <div>
                <GatsbyImage
                  image={getImg}
                  alt={poster?.description || ''}
                  title={poster?.title || ''}
                  className={classes.posterImage}
                  layout="constrained"
                  imgStyle={{ height: isMobileDevice ? '125vw' : '100%' }}
                />
                {isTouchAble && (
                  <div className={classes.playButton}>
                    <Play />
                  </div>
                )}
              </div>
            )}
            {!getImg && youtubeId && (
              <img
                className={classes.posterImage}
                src={`//i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg`}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}
